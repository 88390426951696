exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-affiliate-disclosure-js": () => import("./../../../src/pages/affiliateDisclosure.js" /* webpackChunkName: "component---src-pages-affiliate-disclosure-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contentful-gifts-title-js": () => import("./../../../src/pages/{ContentfulGifts.title}.js" /* webpackChunkName: "component---src-pages-contentful-gifts-title-js" */),
  "component---src-pages-gifts-js": () => import("./../../../src/pages/gifts.js" /* webpackChunkName: "component---src-pages-gifts-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-pages-testing-js": () => import("./../../../src/pages/testing.js" /* webpackChunkName: "component---src-pages-testing-js" */),
  "component---src-templates-tag-template-js": () => import("./../../../src/templates/tag-template.js" /* webpackChunkName: "component---src-templates-tag-template-js" */)
}

